import React from 'react'
import { Link, graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import Nav from '../components/nav'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const EntryPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />
      
      <div className="w-full py-36 font-verdana flex-grow-0 flex-shrink-0 basis-full relative">
        <div className="relative z-30 flex items-center justify-center text-center w-full h-full">
          <div className="w-10/12 lg:w-2/3">
            <h1 className="font-bold text-4xl lg:text-6xl text-white leading-none">{entry.frontmatter.heading}</h1>
          </div>
        </div>
        <div className="absolute -inset-0 z-20 w-full h-full bg-black opacity-25" /> 
        <div className="absolute -inset-0 z-10 w-full h-full">
          <GatsbyImage className="w-full h-full object-cover" image={image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} /> 
        </div>
      </div>

      <div className="w-full pt-12 pb-12 border-b-2 border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none lg:flex justify-between gap-24">
            <div className="w-full lg:w-3/12">
              <div className="max-w-none font-verdana prose leading-relaxed">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <div className="font-verdana text-xl font-bold text-white mb-12 lg:mb-6 mt-6 bg-red py-3 px-5 inline-block">
                <Link to="/contact/">Enquire now <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
              </div>
            </div>
            <div className="w-full lg:w-9/12">
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-12 text-center">
                <div>
                  <div className="w-full h-48 bg-procoat-1">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Abbey Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-2">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Cloud</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-3">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Desert Sand</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-4">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Ebony</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-5">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Farmer Brown</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-6">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Forest Green</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-7">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Grey Fliar</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-8">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Gull Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-9">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Ironsand</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-10">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Karaka</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-11">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Lichen</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-12">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Mild Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-13">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Mist Green</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-14">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">New Demin Blue</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-15">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Permanent Green</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-16">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Pioneer Red</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-17">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">River Gum</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-18">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Scoria</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-19">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Slate</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-20">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Sorrell</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-21">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Stone</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-22">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Terracota</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-23">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Thunder Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-24">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Titania</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-25">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Tudor Grey</p>
                </div>
                <div>
                  <div className="w-full h-48 bg-procoat-26">
                    &nbsp;
                  </div>
                  <p className="bg-white py-2 font-verdana font-bold text-blue text-base">Windsor Grey</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default EntryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`